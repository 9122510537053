<template>
  <div id="parents" class="py-5">

    <div class="input-group input-group-lg">
      <span class="input-group-text" style="width: 180px;">両親</span>
      <div
        class="form-select selectparents"
        :class="[{active: motherSelectActive}, {selected: motherId}]"
        @click="selectMother"
      >

        <div v-if="!motherId">母</div>

        <div
          v-else
          id="mother"
          :class="[{selected: motherId}, 'parent']"
        >
          <picture>
            <img :src="motherThumbUrl" :alt="motherName">
          </picture>
          <span>{{motherName}}</span>
        </div>

        <ul>
          <li value="" @click.stop="chooseMother">選択してください</li>
          <li
            v-for="mother in femaleList"
            :key="mother.id"
            :value="mother.id"
            class="choices"
            @click.stop="chooseMother"
          >
            <picture>
              <img :src="thumbUrl(mother.thumbUrl)" :alt="mother.name">
            </picture>
            <span>{{mother.name}}</span>
          </li>
        </ul>
      </div>

      <div
        class="form-select selectparents"
        :class="[{active: fatherSelectActive}, {selected: fatherId}]"
        @click="selectFather"
      >

        <div v-if="!fatherId">父</div>

        <div
          v-else
          id="father"
          :class="[{selected: fatherId}, 'parent']"
        >
          <picture>
            <img :src="fatherThumbUrl" :alt="fatherName">
          </picture>
          <span>{{fatherName}}</span>
        </div>


        <ul>
          <li value="" @click.stop="chooseFather">選択してください</li>
          <li
            v-for="father in maleList"
            :key="father.id"
            :value="father.id"
            class="choices"
            @click.stop="chooseFather"
          >
            <picture>
              <img :src="thumbUrl(father.thumbUrl)" :alt="father.name">
            </picture>
            <span>{{father.name}}</span>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ["mother", "father"],
  emits: ["motherId", "fatherId"],
  data() {
    return {
      fatherSelectActive: false,
      fatherId: "",
      fatherName: "",
      fatherThumbUrl: "",
      motherSelectActive: false,
      motherId: "",
      motherName: "",
      motherThumbUrl: "",
    }
  },
  mounted() {
    this.$store.dispatch("Kennel/loadAnimalList")
  },
  methods: {
    selectFather() {
      this.fatherSelectActive = !this.fatherSelectActive
    },
    chooseFather(e) {
      if(e.target.value){
        let father = this.maleList.find( (row) => row.id === String(e.target.value) )
        if(!father)
          return

        this.fatherId = father.id
        this.fatherName = father.name
        this.fatherThumbUrl = father.thumbUrl
        this.$emit("fatherId", this.fatherId)
      }
      this.fatherSelectActive = false
    },
    selectMother() {
      this.motherSelectActive = !this.motherSelectActive
    },
    chooseMother(e) {
      if(e.target.value){
        let mother = this.femaleList.find( (row) => row.id === String(e.target.value) )
        if(!mother)
          return

        this.motherId = mother.id
        this.motherName = mother.name
        this.motherThumbUrl = mother.thumbUrl
        this.$emit("motherId", this.motherId)
      }
      this.motherSelectActive = false
    }
  },
  computed: {
    maleList() {
      return this.$store.getters["Kennel/maleList"]
    },
    femaleList() {
      return this.$store.getters["Kennel/femaleList"]
    },
    thumbUrl() {
      return function(url){
        return url ? url : "@/assets/ico_dog.png"
      }
    }
  },
  watch: {
    mother(newVal) {
      let mother = this.femaleList.find( (row) => row.id === String(newVal) )
      if(!mother)
        return

      this.motherId = mother.id
      this.motherName = mother.name
      this.motherThumbUrl = mother.thumbUrl
    },
    father(newVal) {
      let father = this.maleList.find( (row) => row.id === String(newVal) )
      if(!father)
        return

      this.fatherId = father.id
      this.fatherName = father.name
      this.fatherThumbUrl = father.thumbUrl
    }
  }
}
</script>

<style scoped>
.selectparents {
  position: relative;
  padding: 0;
  user-select: none;
}
.selectparents li,
.selectparents > div {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}
.selectparents .parent:not(.selected) {
  padding: 0;
}
.selectparents ul {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  max-height: 300px;
  overflow: scroll;
  padding: 0;
  margin: 0;
  display: none;
  background-color: #fff;
  border: 1px solid #ddd;
}
.selectparents.active ul {
  display: block;
}
.parent,
.selectparents li {
  list-style: none;
  display: flex;
  align-items: center;
  transition: all .2s;
}
.selectparents ul li.choices:hover {
  background-color: var(--bs-primary);
  color: #fff;
}
.parent picture,
.selectparents li picture {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin-right: 10px;
}
.parent picture img,
.selectparents li picture img {
  width: 100%;
  height: auto;
}
.selectparents li picture,
.selectparents li span {
  pointer-events: none;
}
</style>